<script>
import { Doughnut, mixins } from "vue-chartjs";

const { reactiveProp } = mixins;

export default {
  name: "DoughnutChart",
  mixins: [Doughnut, reactiveProp],
  props: {
    chartData: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    tooltips: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  mounted() {
    this.renderChart(this.chartData, {
      cutoutPercentage: 75,
      legend: {
        display: false,
        labels: {
          fontFamily: "Nunito Sans",
        },
      },
      tooltips: this.tooltips,
    });
  },
};
</script>

<style scoped>
</style>