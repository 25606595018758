// Create global mixins
export const colors = {
  data() {
    return {
      colors: {
        blue: "#235a91",
        lightBlue: "#22a6df",
        lightBlueTint0: "#1B84B1",
        lightBlueTint1: "#5EBCE8",
        lightBlueTint2: "#94D3F0",
        lightBlueTint3: "#C9E9F7",
        lightBlueTint4: "#EDF8FC",
        lightBlueFade: "#B8E2F5",
        darkBlue: "#233267",
        darkBlueFade: "#A4B1E0",
        darkBlueBg: "#100c48",
        yellow: "#efad1f",
        orange: "#ed7d2e",
        green: "#1C998B",
        red: "#FF006E",
        purple: "#8d567a",
        gray: "#343434",
        lightGray: "#D0D0D0",
        mediumGray: "#878686",
        fadeGray: "#f0f0f0",
      },
    };
  },
};
